import React from 'react';
import { Navigate } from 'react-router-dom';
import RouteType from './Route.type';
import Test from '../pages/other/Test';
import Chats from '../pages/chats/Chats';
import PoojaApp from '../pages/pooja/PoojaApp';
import AstroGuruFind from '../pages/astroGuru/AstroGuruFind';
import AstroGuruBookingForm from '../pages/astroGuru/AstroGuruBookingForm';
import ChatComponent from '../pages/chat/ChatComponent';
import GemstoneList from '../pages/gemstone/GemstoneList';
import GemstoneDetail from '../pages/gemstone/GemstoneDetail';
import GemstoneOrder from '../pages/gemstone/GemstoneOrder';
import AbhimantritProducts from '../pages/abhimantritProduct/AbhimantritProducts';
import AbhimantritProductDetails from '../pages/abhimantritProduct/AbhimantritProductDetails';
import CounselorOnboard from '../pages/counselor/CounselorOnboard';
import CounselorPersonalInfo from '../pages/counselor/CounselorPersonalInfo';
import CounselorDetails from '../pages/counselor/CounselorDetails';

const Home = React.lazy(() => import('../pages/home/Home'));
const ViewPooja = React.lazy(() => import('../pages/pooja/ViewPooja'));
const PoojaHomeContinue = React.lazy(() => import('../pages/pooja/PoojaHomeContinue'));
const Pooja = React.lazy(() => import('../pages/pooja/Pooja'));
const FaqsPuja = React.lazy(() => import('../pages/faqPuja/FaqsPuja'));
const AfterPujaAddOn = React.lazy(() => import('../pages/order/AfterPujaAddOn'));
const AfterPujaAddOnSuccess = React.lazy(() => import('../pages/order/AfterPujaAddOnSuccess'));
const PoojaHome = React.lazy(() => import('../pages/pooja/PoojaHome'));
const Products = React.lazy(() => import('../pages/product/Products'));
const Process = React.lazy(() => import('../pages/pooja/Process'));
const ProductDetails = React.lazy(() => import('../pages/product/ProductDetails'));
const Cart = React.lazy(() => import('../pages/cart/Cart'));
const ReelsPage = React.lazy(() => import('../pages/reels/ReelsPage'));
const Auth = React.lazy(() => import('../pages/auth/Auth'));
const SuccessPage = React.lazy(() => import('../pages/cart/SuccessPage'));
const CouponPage = React.lazy(() => import('../pages/couponPage/CouponPage'));
const OrderConfirmation = React.lazy(() => import('../pages/order/OrderConfirmation'));
const MyOrders = React.lazy(() => import('../pages/order/MyOrders'));
const PaymentScreen = React.lazy(() => import('../pages/order/PaymentScreen'));
const ExchangePolicy = React.lazy(() => import('../pages/other/ExchangePolicy'));
const PrivacyPolicy = React.lazy(() => import('../pages/other/PrivacyPolicy'));
const TAndC = React.lazy(() => import('../pages/other/TAndC'));
const ShipingPolicy = React.lazy(() => import('../pages/other//ShipingPolicy'));
const KundaliReport = React.lazy(() => import('../pages/kundali/KundaliReport'));
const Chadhaava = React.lazy(() => import('../pages/chdhava/Chadhaava'));
const ChadhaavaDetails = React.lazy(() => import('../pages/chdhava/ChadhaavaHome'));
const ChadhaavaPaymentConfrimation = React.lazy(() => import('../pages/chdhava/ViewChadhaava'));
const NamesCorrection = React.lazy(() => import('../pages/namesCorrection/NamesCorrection'));

const defaultRoutes: RouteType[] = [
    { path: '/', component: <Home /> },
    { path: '/chats/:id', component: <Chats /> },
    { path: '/auth', component: <Auth /> },
    // { path: '/reels', component: <ReelsPage /> },
    { path: '/faqs-and-testimonials', component: <FaqsPuja /> },

    { path: '/chadhaava', component: <Chadhaava /> },
    // { path: '/online-puja-app', component: <PoojaApp /> },
    { path: '/chadhaava/:id', component: <ChadhaavaDetails /> },
    { path: '/chadhaava/payment-confirmation', component: <ChadhaavaPaymentConfrimation /> },

    { path: '/name-correction', component: <NamesCorrection /> },

    { path: '/online-puja', component: <Pooja /> },
    { path: '/online-puja-app', component: <PoojaApp /> },
    { path: '/pooja/:id', component: <PoojaHome /> },
    { path: '/pooja/:id/continue', component: <PoojaHomeContinue /> },
    { path: '/pooja/payment-confirmation', component: <ViewPooja /> },
    { path: '/astro-guru/find', component: <AstroGuruFind /> },
    { path: '/astro-guru/form', component: <AstroGuruBookingForm /> },
    { path: '/counselor-onboard', component: <CounselorOnboard /> },
    { path: '/counselor-info', component: <CounselorPersonalInfo /> },
    { path: '/counselor/:id', component: <CounselorDetails /> },
    { path: '/success-page/:id/:type/:name', component: <SuccessPage /> },
    // { path: '/order-confirmation/:id', component: <OrderConfirmation /> },
    // { path: '/puja-process', component: <Process label={} /> },
    { path: '/orders/:id/:name', component: <MyOrders /> },
    { path: '/ChatComponent', component: <ChatComponent /> },
    { path: '/gemstones', component: <GemstoneList /> },
    { path: '/gemstones/:id', component: <GemstoneDetail /> },
    { path: '/:type/:id/order', component: <GemstoneOrder /> },
    // { path: '/payment/:id', component: <PaymentScreen /> },
    // { path: '/coupon', component: <CouponPage /> },
    { path: '/real-temple-model', component: <Products /> },
    { path: '/abhimantrit-products', component: <AbhimantritProducts /> },
    { path: '/abhimantrit-products/:id', component: <AbhimantritProductDetails /> },
    // { path: '/testimonials', component: <Testimonials /> },
    // { path: '/products/:id/:name', component: <ProductDetails /> },
    // { path: '/cart', component: <Cart /> },
    { path: '/kundali-report/:order_id', component: <KundaliReport /> },
    { path: '/exchange-policy', component: <ExchangePolicy /> },
    { path: '/after-puja-add-on/:id', component: <AfterPujaAddOn /> },
    { path: '/after-puja-add-on/success/:id', component: <AfterPujaAddOnSuccess /> },
    { path: '/privacy-policy', component: <PrivacyPolicy /> },
    { path: '/term-and-condition', component: <TAndC /> },
    { path: '/shipping-policy', component: <ShipingPolicy /> },
    { path: '*', component: <Navigate to="/" /> }
];

export { defaultRoutes };
