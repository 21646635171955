import React, { useState } from "react";
import { capitalizeFirstLetter } from "../../utils/helper.utils";
import { useNavigate } from "react-router-dom";
import { Typography } from "antd";
import  BottomNavBar from "../../layouts/BottomNavBar"

const AbhimantritProductGrid = ({ products, type }) => {
  const navigate = useNavigate();

  // Sort products by rank, with lower rank values appearing first
  const sortedProducts = products.sort((a, b) => a.rank - b.rank);

  return (
 <> 
    <div className="grid grid-cols-1 gap-3  bg-gray-100">
      {sortedProducts.map((product) => (
        <div className="bg-white rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 ease-in-out pb-2">
          <div
            onClick={() =>
              navigate(`/${type || "abhimantrit-products"}/${product.id}`, {
                state: product,
              })
            }
            key={product.id}
            className="flex "
          >
            {/* Image container with badge */}
            <div className="relative w-[150px] h-[150px]">
              <img
                alt={product.name}
                src={
                  product.images?.length > 0
                    ? product.images[0]
                    : "https://devpunya.com/static/media/logo_image.dbe451c63a7253b30299.jpeg"
                }
                className="w-full h-full object-contain"
              />

              {/* Optional decoration (badge) */}
              {product.decoration && (
                <div className="absolute top-0 left-0 bg-orange-500 text-white rounded-full px-2 py-1 text-xs">
                  {capitalizeFirstLetter(product.decoration)}
                </div>
              )}
            </div>

            {/* Content on the right */}
            <div className="flex flex-col justify-between p-4 w-full">
              {/* <Title product={product}     */}

              <h3
                style={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2, // Limits the text to 1 lines
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {product.name}
              </h3>

              {/* Benefits Titles */}
              {/* {product?.tabs?.data?.find((tab) => tab.key === "benefits") && (
              <BenefitsTitles product={product} />
            )} */}

              <h3
                className="text-sm text-orange-500"
                style={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2, // Limits the text to 1 lines
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {product.benefit}
              </h3>

              <div className="flex items-center justify-between  ">
                <div className="flex items-center">
                  <p className="text-lg text-green-500 font-bold">
                    ₹{product.discounted_price}
                  </p>
                  {product.discounted_price !== product.price && (
                    <p className="text-gray-400 line-through ml-2">
                      ₹{product.price}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Desc product={product} />
        </div>
      ))}
    </div>
    < BottomNavBar/>
    </>
   
  );

};

export default AbhimantritProductGrid;

function Desc({ product }) {
  const [desc] = useState(
    product?.description?.length > 115
      ? product?.description?.slice(0, 115) + "..."
      : product.description
  );
  return (
    <Typography.Text type="secondary" className="text-xs block px-2">
      {desc}
    </Typography.Text>
  );
}

// function Title({ product }) {
//   const [title] = useState(
//     product?.name?.length > 80
//       ? product?.name?.slice(0, 80) + "..."
//       : product.name
//   );
//   return (
//     <Typography.Text className="text-sm font-medium">{title}</Typography.Text>
//   );
// }
