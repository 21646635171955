// import React, { useEffect, useState } from "react";
// import { Button, Typography } from "antd";
// import { WhatsAppOutlined, ArrowRightOutlined } from "@ant-design/icons";
// import { useNavigate } from "react-router-dom";
// import moment from "moment";
// import { WhatsappIcon, WhatsappShareButton } from "react-share";
// import { IoLocateOutline, IoCalendar } from "react-icons/io5";
// import Sampark from "../../../components/Sampark";
// import { IoCalendarOutline } from "react-icons/io5";
// import { color } from "html2canvas/dist/types/css/types/color";
// import Item from "antd/es/list/Item";

// const TrendingPujaCard = ({ item }: any) => {
//   const [desc, setDesc] = useState<string>(
//     item.description?.slice(0, 40) + "..."
//   );
//   const [more, setMore] = useState<boolean>(false);

//   useEffect(() => {
//     setDesc(more ? item.description : item.description?.slice(0, 40) + "...");
//   }, [more]);

//   const navigate = useNavigate();

//   const navigateToPoojaDetails =()=>{
//     navigate(`/pooja/${item.id}`)
//   }

//   return (
//     <>
//     <div className="max-w-sm mx-auto bg-white rounded-lg shadow-md overflow-hidden" onClick={navigateToPoojaDetails}>

//     <div className="relative">
//       {/* <img
//         src={item?.default_image ? item?.default_image : item?.images[0]}
//           alt="Puja"
//         className="w-full h-48 object-cover rounded-t-lg"
//       /> */}
//        <img
//           className="object-cover w-full border bg-gray-300"
//           src={item?.default_image ? item?.default_image : item?.images[0]}
//           alt="Puja"
//           style={{ height: "130px" }}
//         />

//       <div className="absolute  left-3 bottom-3 flex  gap-2">

//         {/* <span className=" text-white px-2 py-0.5 text-xs rounded-full" style={{backgroundColor:"#FF9F02B0"}}>
//         {item?.decoration_tag||""}
//         </span> */}
//         {item?.decoration_tag && (
//   <span
//     className="text-white px-2 py-0.5 text-xs rounded-full"
//     style={{ backgroundColor: "#FF9F02B0" }}
//   >
//     {item.decoration_tag}
//   </span>
// )}

//       </div>
//     </div>

//     <div className="p-4">
//       <h2 className="text-lg" style={{fontWeight:"500", color:"#101010"}}>
//          {item.name}
//          {/* <span
//             onClick={(e) => {
//               e.stopPropagation();
//               setMore(!more);
//             }}
//             className="text-blue-500 text-sm"
//           >
//             read {more ? "less" : "more"}
//           </span> */}

//       </h2>
//       <h3 className="text-sm text-orange-500">{item.benefits}</h3>

//       <h3 className="text-xs font-light text-gray-400 mb-2">
//           {desc}
//           <span
//             onClick={(e) => {
//               e.stopPropagation();
//               setMore(!more);
//             }}
//             className="text-blue-500"
//           >
//             read {more ? "less" : "more"}
//           </span>
//         </h3>
//       {/* <p className="text-sm" style={{color:"#939393"}}>{item.description}</p> */}

//       <div className="mt-4 flex items-center text-sm" style={{color:"#939393"}}>
//         <IoCalendarOutline className="mr-2" />
//         <span>{moment(item.startingAt).format("DD MMMM YYYY")}</span>
//       </div>
//       <div className="mt-2 flex items-center text-sm" style={{color:"#939393"}}>
//           <IoLocateOutline />
//           <span className="ml-2 text-sm">
//             {item.mandir_name}, {item.location}
//           </span>
//         </div>
//     </div>
//   </div>
//   </>
//   );
// };

// export default TrendingPujaCard;

import React, { useEffect, useState } from "react";
import { Button, Typography } from "antd";
import { WhatsAppOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { WhatsappIcon, WhatsappShareButton } from "react-share";
import { IoLocateOutline, IoCalendar } from "react-icons/io5";
import Sampark from "../../../components/Sampark";
import { IoCalendarOutline } from "react-icons/io5";
import { color } from "html2canvas/dist/types/css/types/color";
import Item from "antd/es/list/Item";

const TrendingPujaCard = ({ item, width }: any) => {
  const [desc, setDesc] = useState<string>(
    item.description?.slice(0, 40) + "..."
  );
  const [more, setMore] = useState<boolean>(false);

  useEffect(() => {
    setDesc(more ? item.description : item.description?.slice(0, 110) + "...");
  }, [more]);

  const navigate = useNavigate();

  const navigateToPoojaDetails = () => {
    navigate(`/pooja/${item.id}`);
  };

  return (
    <div
      className={`${
        width ? `${width} mr-2` : "w-96 mx-2"
      }  bg-white rounded-lg shadow-md`}
      onClick={navigateToPoojaDetails}
    >
      <div className="relative">
        {/* <img
        src={item?.default_image ? item?.default_image : item?.images[0]}
          alt="Puja"
        className="w-full h-48 object-cover rounded-t-lg"
      /> */}
        <img
          className="object-cover w-full border bg-gray-300 rounded-lg"
          src={item?.default_image ? item?.default_image : item?.images[0]}
          alt="Puja"
          style={{ height: "130px" }}
        />

        <div className="absolute  left-3 bottom-3 flex  gap-2">
          {/* <span className=" text-white px-2 py-0.5 text-xs rounded-full" style={{backgroundColor:"#FF9F02B0"}}>
        {item?.decoration_tag||""}
        </span> */}
          {item?.decoration_tag && (
            <span
              className="text-white px-2 py-0.5 text-xs rounded-full"
              style={{ backgroundColor: "#FF9F02B0" }}
            >
              {item.decoration_tag}
            </span>
          )}
        </div>
      </div>

      <div className="p-4">
        {/* <h2 className="text-lg" style={{fontWeight:"500", color:"#101010"}}>
         {item.name}
        


      </h2> */}

        <h2
          className="text-lg"
          style={{
            fontWeight: "500",
            color: "#101010",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1, // Limits the text to 2 lines
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {item.name}
        </h2>

        <h3
          className="text-sm text-orange-500"
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1, // Limits the text to 1 lines
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {item.benefits}
        </h3>

        <h3 className="text-xs font-light text-gray-400 mb-2">
          {desc}
          <span
            onClick={(e) => {
              e.stopPropagation();
              setMore(!more);
            }}
            className="text-blue-500"
          >
            read {more ? "less" : "more"}
          </span>
        </h3>

        <div
          className="mt-4 flex items-center text-sm"
          style={{ color: "#939393" }}
        >
          <IoCalendarOutline className="mr-2" />
          <span>{moment(item.startingAt).format("DD MMMM YYYY")}</span>
        </div>
        <div
          className="mt-2 flex items-center text-sm"
          style={{ color: "#939393" }}
        >
          <IoLocateOutline />
          <span className="ml-2 text-sm">
            {item.mandir_name}, {item.location}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TrendingPujaCard;
