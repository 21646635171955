import React from "react";
import { useNavigate } from "react-router-dom";

import AstroGuru from "../assets/astrology.png";
import book_puja from "../assets/book_puja.png";
import play from "../assets/play.png";
import yantra from "../assets/torus-yantra.png";
import My_Order from "../assets/check-out.png";
import parchment from "../assets/parchment.png";
import nametag from "../assets/name-tag.png";

function BottomNavBar() {
  const navigate = useNavigate();
  
  return (
    <div className="bottom-nav-bar fixed bottom-0 left-0 right-0 bg-white flex justify-between items-center px-6 h-20 shadow-md border-t border-gray-200">
      <div
        className="nav-item flex flex-col items-center"
        onClick={() => navigate("/online-puja")}
      >
        <img src={book_puja} className="h-8 w-8" />
        <span className="nav-label text-xs text-orange-500">Puja</span>
      </div>

      <div
        className="nav-item flex flex-col items-center"
        onClick={() => navigate("/astro-guru/find")}
      >
        <img src={AstroGuru} className="h-8 w-8" />
        <span className="nav-label text-xs text-orange-500">Astro</span>
      </div>

      {/* <div className="nav-item flex flex-col items-center">
      <img src={yantra} className="h-8 w-8"/> 
        <span className="nav-label text-xs text-orange-500">Store</span>
      </div> */}

      <div
        className="nav-item flex flex-col items-center"
        onClick={() => {
          navigate(`/orders/1/puja`);
        }}
      >
        <img src={My_Order} className="h-8 w-8" />
        <span className="nav-label text-xs text-orange-500">My Orders</span>
      </div>
      <div
        className="nav-item flex flex-col items-center"
        onClick={() => navigate(`/name-correction/`)}
      >
        <img src={nametag} className="h-8 w-8" />

        <span className="nav-label text-xs text-orange-500">
          Name Correction
        </span>
      </div>
    </div>
  );
}

export default BottomNavBar;